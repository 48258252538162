import React from 'react'
import BOOKING_LINK from '../../../js/BOOKING_LINK'
import { Link } from 'gatsby'

const BookingCta = () => {
  return (
    <div className="container">
      <div className="row p-4 text-center">
        <div className="col-md-6 p-3">
          <h3>
            <a href={BOOKING_LINK} rel="noreferrer" target="_blank">
              Make a Booking
            </a>
          </h3>
        </div>
        <div className="col-md-6 p-3">
          <h3>
            <Link to="/contact">Contact Us</Link>
          </h3>
        </div>
      </div>
    </div>
  )
}

export default BookingCta
